@use "sass:math";
@use "variables" as *;

$max-width: calculate-rem(800px);

.not-supported-container {
  width: 100%;
}

.not-supported-title-icon {
  text-align: center;
  padding-bottom: calculate-rem(30px);
}

.not-supported-title-icon img {
  width: calculate-rem(80px);
  height: calculate-rem(80px);
}

.not-supported-img-backdrop {
  display: table;
  position: relative;
  margin: auto;
  padding: calculate-rem(86px) calculate-rem(20px) calculate-rem(50px);
}

.not-supported-title {
  margin-bottom: calculate-rem(48px);
  text-align: center;
}

.not-supported-card {
  max-width: $max-width;
  background: white;
  border: 1px solid var(--light-gray-border-color);
  padding: calculate-rem(20px);
}

.not-supported-message {
  text-align: center;
  font-size: calculate-rem(15px);
}

.not-supported-browser-list {
  display: table;
  width: 100%;
  max-width: $max-width;
  margin-top: calculate-rem(48px);
  background: var(--grey-color);
}

.not-supported-browser-icon {
  float: left;
  text-align: center;
  padding-top: calculate-rem(20px);
  padding-bottom: calculate-rem(20px);
  width: calc(#{$max-width} / 5);
}

.not-supported-browser-icon img {
  height: calculate-rem(36px);
}

@media all and (max-width: #{$max-width}) {
  .not-supported-browser-list {
    width: 100% !important;
    float: none !important;
  }
}
