@use '@angular/material' as mat;

:root {
  --form-stepper-circle-text: white;
  --icon-hover-background-color: #CBCBCB28;
  --side-menu-backdrop-color: #000F;
  --white-color: white;
  --black-color: black;
  --grey-color: grey;
  --mat-success-color: #{mat.get-color-from-palette(mat.$green-palette, 500)};
  --mat-error-color: #{mat.get-color-from-palette(mat.$red-palette, 500)};
  --mat-warning-color: #{mat.get-color-from-palette(mat.$orange-palette, 500)};
  --mat-info-color: #{mat.get-color-from-palette(mat.$light-blue-palette, 500)};
  --mat-grey-color: #{mat.get-color-from-palette(mat.$grey-palette, 500)};
  --social-login-button-background: #909090;
  --custom-foreground-color: #43425DFF;
  --color-picker-input-prefix-disabled-color: #43425D66;
  --global-loader-backdrop-color: #0000007F;
  --light-gray-border-color: lightgray;
  --color-picker-circle-disabled-color: lightgray;
}
