$modal-header-mobile-height: 90px;
$modal-footer-mobile-height: 90px;

$dialog-padding: 24px;

// global style
.cdk-overlay-pane {
  .mat-mdc-dialog-container {
    padding: 0 $dialog-padding;

    app-modal-header,
    app-modal-content,
    app-modal-footer {
      display: table;
      width: 100%;
    }

    app-modal-header {
      position: relative;
      padding-top: $dialog-padding;
    }

    app-modal-footer {
      padding-bottom: $dialog-padding;
    }
  }
}

// mobile style
.cdk-overlay-pane.mobile {
  width: 100vw !important;
  max-width: 100vw !important;
  height: 100% !important;

  .mat-mdc-dialog-container {
    border-radius: 0 !important;

    app-modal-header {
      height: $modal-header-mobile-height;
    }

    app-modal-content {
      overflow-y: auto;
      height: calc(100% - #{$modal-header-mobile-height} - #{$modal-footer-mobile-height});
    }

    app-modal-footer {
      height: $modal-footer-mobile-height;
    }
  }
}
