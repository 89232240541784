@use "sass:math";

$base-font-size: 16px;
$font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;

@function calculate-rem($size) {
  $rem-size: math.div($size, $base-font-size);
  @return #{$rem-size}rem;
}

$app-header-height: calculate-rem(50px);
$app-header-mobile-height: calculate-rem(70px);

$app-modal-padding: calculate-rem(15px) calculate-rem(24px);

$side-menu-width: calculate-rem(250px);
$sticky-bar-width: calculate-rem(40px);
$side-menu-content-z-index: 9;
$side-menu-backdrop-z-index: $side-menu-content-z-index + 1;
$side-menu-panel-z-index: $side-menu-backdrop-z-index + 1;
$side-menu-sticky-bar-z-index: $side-menu-panel-z-index + 1;

$login-padding: 25px;
$login-logo-left-size: 45px;
$login-site-name-font: 'Poiret One', cursive;
$login-site-name-font-size: 23px;
$login-site-name-line-height: 27px;
$login-header-font: 'Montserrat', sans-serif;
$login-header-font-size: 35px;
$login-header-letter-spacing: 7px;
$login-subtitle-font-size: 18px;
$login-top-right-svg-box-size: 350px;
$login-top-right-svg-scale: 1.5;
$login-button-google-logo-width: 25px;
$login-button-google-logo-height: 15px;


