/* You can add global styles to this file, and also import other style files */
@use 'bootstrap/scss/bootstrap';
@use 'bootstrap/scss/bootstrap-grid';

@use 'main';
@use 'modal';
@use 'index/main-loading';
@use 'index/browser-not-supported';
@use 'material';

/* Color variables and palettes for light and dark theme */
@use 'colors/common-colors';
@use 'colors/light-theme';
@use 'colors/dark-theme';


html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
