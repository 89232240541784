@use '@angular/material' as mat;
@use 'material-variables' as mat-variables;

@import "material-design-icons/iconfont/material-icons.css";
@import 'variables';
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import 'colors/dark-theme';

// IMPORTANT!
// Do not import this file anywhere because it can cause unexpected css behaviour
// For every material variable you want to use, add it to material-variables.scss

// LIGHT THEME

@function custom-foreground($color) {
  @return (
    base: $color,
    divider: rgba($color, 0.06),
    dividers: rgba($color, 0.12),
    disabled: rgba(black, 0.38),
    disabled-button: rgba($color, 0.38),
    disabled-text: rgba($color, 0.38),
    hint-text: rgba($color, 0.38),
    secondary-text: rgba($color, 0.54),
    icon: rgba($color, 0.54),
    icons: rgba($color, 0.54),
    text: rgba($color, 0.87),
    slider-off: rgba($color, 0.26),
    slider-off-active: rgba($color, 0.38),
    slider-min: rgba($color, 0.38)
  )
}

$foreground: custom-foreground(var(--custom-foreground-color));
$primary-light: mat.define-palette(mat.$cyan-palette);
$accent-light: mat.define-palette(mat.$blue-palette);
$warn-light: mat.define-palette(mat.$red-palette);

$theme: map-merge(mat.define-light-theme((
  color: (
    primary: $primary-light,
    accent: $accent-light,
    warn: $warn-light,
  ),
  typography: mat-variables.$custom-typography,
  density: 0,
)), (foreground: $foreground));

@include mat.all-component-themes($theme);
@include mat.all-component-typographies(mat-variables.$custom-typography);

// update input density
// @include mat.form-field-theme(mat.define-light-theme((density: -2)));

// DARK THEME

$primary-dark: mat.define-palette(mat.$light-green-palette, 'A700');
$accent-dark: mat.define-palette(mat.$purple-palette, 500, 100, 900);
$warn-dark: mat.define-palette(mat.$indigo-palette, 500, 100, 900);

$dark-theme: mat.define-dark-theme((
  color: (
    primary: $primary-dark,
    accent: $accent-dark,
    warn: $warn-dark,
  ),
  density: -2,
),
);

.dark-mode {
  @include mat.all-component-colors($dark-theme);
}
