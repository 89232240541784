@use '@angular/material' as mat;

$dark-custom-palette: (
  50 : #f9e0e0,
  100 : #f0b3b3,
  200 : #e68080,
  300 : #db4d4d,
  400 : #d42626,
  500 : #cc0,
  600 : #c70000,
  700 : #c00000,
  800 : #b90000,
  900 : #ad0000,
  A100 : #ffd7d7,
  A200 : #ffa4a4,
  A400 : #ff7171,
  A700 : #ff5858,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #fff,
    400 : #fff,
    500 : #fff,
    600 : #fff,
    700 : #fff,
    800 : #fff,
    900 : #fff,
    A100 : #000,
    A200 : #000,
    A400 : #000,
    A700 : #000,
  )
);

.dark-mode {
  --primary-color: #{mat.get-color-from-palette(mat.$light-green-palette, A700)};
  --side-menu-text-color: var(--primary-color);
  --side-menu-item-background-color: #588c5f;
  --side-menu-item-hover-background: #639d6c;
  --low-res-bg-color: #{mat.get-contrast-color-from-palette($dark-custom-palette, 300)};
  --form-stepper-circle-background: #b39ddb;
  --breadcrumb-text-color: #ba91d3;
  --color-picker-border-color: #FFF9;
  --color-picker-input-prefix-color: #FFF9;
  --color-picker-circle-disabled-color: #e5e4e44a;
  --bs-link-color: #{mat.get-color-from-palette(mat.$light-blue-palette, 400)};
  --bs-link-hover-color: #{mat.get-color-from-palette(mat.$light-blue-palette, 700)};
}
