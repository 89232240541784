@use "variables" as *;

html {
  overflow-x: hidden;
  font-size: $base-font-size;
  height: 100%;
}

body {
  overflow-x: hidden;
  font-size: $base-font-size;
  font-family: $font-family;
  margin: 0;
  height: 100%;
}

.bordered {
  border: 1px solid var(--black-color);
  padding: calculate-rem(10px);
}

.error-message {
  color: var(--mat-error-color);
  height: calculate-rem(22px);
  font-size: 0.8rem;
}
