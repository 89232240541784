@use '@angular/material' as mat;

$light-custom-palette: (
  50 : #e8eaf6,
  100 : #c5cbe9,
  200 : #9fa8da,
  300 : #7985cb,
  400 : #5c6bc0,
  500 : #3f51b5,
  600 : #394aae,
  700 : #3140a5,
  800 : #29379d,
  900 : #1b278d,
  A100 : #c6cbff,
  A200 : #939dff,
  A400 : #606eff,
  A700 : #4757ff,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #000,
    400 : #fff,
    500 : #fff,
    600 : #fff,
    700 : #fff,
    800 : #fff,
    900 : #fff,
    A100 : #000,
    A200 : #000,
    A400 : #fff,
    A700 : #fff,
  )
);

:root {
  --primary-color: #{mat.get-color-from-palette(mat.$cyan-palette, 500)};
  --side-menu-text-color: var(--primary-color);
  --side-menu-item-background-color: #80deea;
  --side-menu-item-hover-background: #aad5fa;
  --low-res-bg-color: #{mat.get-contrast-color-from-palette($light-custom-palette, 300)};
  --form-stepper-circle-background: #80deea;
  --breadcrumb-text-color: #01a0c6;
  --color-picker-border-color: #00000060;
  --color-picker-input-prefix-color: #00000060;
  --color-picker-circle-disabled-color: lightgrey;
}
